import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DynamicLoaderService {
  private loadedLibraries: { [url: string]: ReplaySubject<boolean> } = {};
  private loadedScripts: { [url: string]: HTMLScriptElement } = {};

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  loadCss(url: string): Observable<boolean> {
    if (this.loadedLibraries[url]) {
      return this.loadedLibraries[url].asObservable().pipe(take(1));
    }

    this.loadedLibraries[url] = new ReplaySubject<boolean>();

    const css = this.document.createElement('link');
    css.rel = 'stylesheet';
    css.href = url;
    css.onload = () => {
      this.loadedLibraries[url].next(true);
    };
    css.onerror = () => {
      this.loadedLibraries[url].next(false);
    };

    this.document.body.appendChild(css);

    return this.loadedLibraries[url].asObservable().pipe(take(1));
  }

  loadScript(url: string, options?: { id?: string }): Observable<boolean> {
    if (this.loadedLibraries[url]) {
      return this.loadedLibraries[url].asObservable().pipe(take(1));
    }

    this.loadedLibraries[url] = new ReplaySubject<boolean>();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    if (options?.id) {
      script.id = options.id;
    }
    script.onload = () => {
      this.loadedLibraries[url].next(true);
    };
    script.onerror = () => {
      this.loadedLibraries[url].next(false);
    };

    this.loadedScripts[url] = this.document.body.appendChild(script);

    return this.loadedLibraries[url].asObservable().pipe(take(1));
  }

  removeScript(url: string): void {
    this.document.body.removeChild(this.loadedScripts[url]);
    delete this.loadedLibraries[url];
    delete this.loadedScripts[url];
  }
}
